
import { defineComponent } from 'vue'
import {
    getContractRecord,
    ContractRecordDto,
    openContractDetail
} from '@/api/contract'
import axios from 'axios'
import { downLoadFile, downloadFileAndChangeName } from '../utils/util'
import { exportContractRecord, downloadSignRecordPdfForFlows } from '@/api/export'
import store from '@/store/index'
import { shortcuts } from '@/utils/global'
export default defineComponent({
    name: 'ContractList',
    data() {
        return {
            selectht: [] as any,
            active: '全部',
            total: 0,
            pageSize: 10,
            pageNum: 1,
            tableData: [] as ContractRecordDto[],
            payDateSolt: '',
            searchValue: '',
            searchText: '',
            selects: [] as string[],
            shortcuts: shortcuts
        }
    },
    created() {
        this.handleCurrentChange()
    },
    methods: {
        handleCurrentChange() {
            getContractRecord({
                pageSize: this.pageSize,
                pageNum: this.pageNum,
                searchText: this.searchValue,
                beginCreateTime: this.payDateSolt ? this.payDateSolt[0] : '',
                endCreateTime: this.payDateSolt ? this.payDateSolt[1] : ''
            }).then(res => {
                this.total = res.total
                this.tableData = res.list
            })
        },
        handleSizeChange() {
            this.pageNum = 1
            this.handleCurrentChange()
        },
        search() {
            this.searchValue = this.searchText
            this.pageNum = 1
            this.handleCurrentChange()
        },
        onReset() {
            this.searchText = ''
            this.payDateSolt = ''
            this.search()
        },
        async downloadSignRecordPdfForFlowsfn(arr: any) {
            const result = await downloadSignRecordPdfForFlows({
                ids: arr
                // ids: [45236, 45240]
            }, {
                loading: false
            })
            console.log(result, 'resultresultresult')
            if (result.length > 0) {
                result.forEach((item: any) => {
                    downloadFileAndChangeName(item.url, item.name + '.' + item.type)
                })
            }
        },
        async onExportht() {
            this.downloadSignRecordPdfForFlowsfn(this.selectht)
        },
        async onExporthtalong(id:any) {
            let arr = [id]
            this.downloadSignRecordPdfForFlowsfn(arr)
        },
        async onExport() {
            const result = await exportContractRecord({
                ids: this.selects.join(','),
                searchText: this.searchValue
            }, {
                loading: true
            })
            if (!result.fileUrl && result.exportStatus === 0) {
                store.commit('getLongPollingFile', result)
            } else {
                axios({
                    url: result.fileUrl,
                    method: 'GET',
                    responseType: 'blob'
                }).then(async (response) => {
                    await downLoadFile(response, result.exportType, result.updateTime, result.remark)
                    await this.$message.success('导出成功！')
                })
            }
            // window.location.href =
            //     process.env.VUE_APP_BASE_URL +
            //         '/common/download?delete=true&fileName=' +
            //         result

            // this.$message.success('导出成功！')
        },
        handleSelectionChange(selects: { id: string }[]) {
            console.log(selects, 'selects')
            this.selects = selects.map(item => item.id)
            this.selectht = []
            selects.forEach((ele: any, i: number) => {
                if (ele.flowType !== 20) {
                    this.selectht.push(ele.id)
                }
            })
            console.log(this.selectht, 'this.selectht')
        },
        async goDetail(flowId: string) {
            const result = await openContractDetail(flowId)
            console.log(result, 'result')
            window.open(result.consoleUrl)
        }
    }
})
